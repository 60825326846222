<script>

import appConfig from "@/app.config";

import {mapGetters, mapState} from "vuex";
import {HalfCircleSpinner} from "epic-spinners";
import {get_acsrf_token} from "@/methods";

export default {
  page: {
    title: "Upgrade",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    HalfCircleSpinner
  },
  validations: {

  },
  computed: {
    ...mapGetters([
      'getEntryPath',
    ])
  },
  methods: {
    rotateMessage() {
      this.messageIndex++;
      this.message = this.$t(`upgrade.messages.${this.messageIndex}`);
      if(this.messageIndex < this.maxMessageIndex) {
        setTimeout(() => {
          this.rotateMessage();
        }, 1000 * 1);
      }
    },
    async performUpgrade() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/@me/upgrade`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          return true;
        } else {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    }
  },
  async mounted() {
    this.rotateMessage();
    await this.performUpgrade();
    await new Promise(resolve => setTimeout(resolve, 1000*1*(this.maxMessageIndex-this.messageIndex+2)));
    if(this.$route.params.next && this.$route.params.next !== '/upgrade-account') await this.$router.push(this.$route.params.next);
    else await this.$router.push('/');
  },
  data() {
    return {
      maxMessageIndex: 3,
      messageIndex: -1,
      message: null
    }
  }
};
</script>

<template>
  <div class="body">
    <div>
      <div id="layout-wrapper" layout="horizontal">
        <div class="main-content" style="margin: unset !important;">
          <div class="page-content min-vh-100">
            <div class="row mt-4">
              <div class="col justify-content-center">
                <div  class="info-component text-center align-middle mt-auto mb-auto">
                  <div class="info-component-space">
                    <half-circle-spinner :animation-duration="900" :size="70" class="align-middle d-inline-block info-component-icon"/>
                  </div>
                  <div class="row" style="margin-top: 20px;">
                    <div class="col-lg-12">
                      <h3>{{$t('upgrade.title')}}</h3>
                      <h4 class="text-muted">{{message}}</h4>
                      <h6 class="text-muted text-uppercase">{{$t('upgrade.disclaimer')}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
